import axios from "@/axios.js";

export default {
  addItem({ commit }, item) {
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios
        .post("/API/Broker/AddBroker", item,{headers:Token})
        .then(response => {
          resolve(response);
          commit("ADD_ITEM", Object.assign(item, { ID: response.data.data.ID }));
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // axios.get("api/Country/GetCountryByID?ID="+ itemid,{headers:Token})
  GetBrokerItem({ commit },ID){
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios
        .get("API/Broker/GetById?ID="+ID,{headers:Token})
        
        .then(response => {
          debugger;
          resolve(response);
         console.log(response)
          commit("SET_BROKER_DATA", response.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  GetBrokerModel({ commit },id){
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios
        .get("API/Broker/GetBrokerModel?id=" + id,{headers:Token})
        .then(response => {
          debugger;
          resolve(response);
          commit("UPDATE_BROKER_DATA", response.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchServiceProviderItems({ commit },id){
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios
        .get(`API/ServicesProvider/GetById/${id}`,{headers:Token})
        .then(response => {
          resolve(response);
         console.log(response)
          commit("SET_ServiceProvider_DATA", response.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  fetchUserItems({ commit },Id){

    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios
        .get(`API/Customer/GetCustomerById/${Id}`,{headers:Token})
        .then(response => {
          resolve(response);
         console.log(response)
          commit("SET_User_DATA", response.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchDataListItems({ commit }) {
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios
        .get("API/Customer/GetAllUsers",{headers:Token})
        .then(response => {
          resolve(response);
          commit("SET_UsersManagements", response.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  SearchUsersData({ commit }, search) {
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      
      axios
        .post("API/Customer/SearchCustomerData", search,{headers:Token})
        .then(response => {
          resolve(response);
          commit("SET_UsersManagements", response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
 
  updateItem(context, item) {
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios
        .post("API/Broker/Update", item,{headers:Token})
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  

  UpdateBroker({ commit }, item) {
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios
      .put("API/Broker/Update", item,{headers:Token})
        .then(response => {
          resolve(response);
          commit("UPDATE_Broker", item);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  GetItemByID(context, itemid) {
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios
      .get("API/UnitOwner/GetUnitOwnerById?Id=" + itemid,{headers:Token})
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  GetUnitOwnersNotApproved() {
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      debugger;
      axios
      .get("API/UnitOwner/GetNewOwnersCount",{headers:Token})
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  GetOwnersNotApproved() {
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      debugger;
      axios
      .get("API/UnitOwner/GetUnitOwnersNotApproved",{headers:Token})
        .then(response => {
          debugger;
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  DeleteBroker({ commit }, Id) {
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios
        .delete("API/Broker/Delete?Id=" +Id,{headers:Token})
        .then(response => {
          resolve(response);
          commit("REMOVE_ITEM", Id);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  BlockBroker(context,Id) {
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios
        .post("API/Broker/BlockBroker?BrokerId=" +Id,{headers:Token})
        .then(response => {
          resolve(response);
         
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  UploadImage(context, image) {
    const formData = new FormData();
    formData.append("file",image, image.name);
    return new Promise((resolve, reject) => {
      axios
        .post("/API/Common/UploadFile", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.status == 200) {
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  UnBlockBroker(context, Id) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .post("API/Broker/UnBlockBroker?Id="+Id)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  ApprovalRequest(context, Id) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .put("API/Authentication/AcceptRegistrationRequests?Id="+Id)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  CancelRequest({ commit }, item) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .delete("API/UnitOwner/DeleteUnitOwnr?Id=" + item)
        .then(response => {
          resolve(response);
          commit("REMOVE_ITEM", item.ID);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

};
