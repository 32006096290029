<template>
  <div>
    <div id="page-user-edit" class="flex">
      <!-- Broker Details -->
      <div class="w-1/3 mr-4">
        <div class="handle-image-container">
          <img
            :src="baseURL + '/' + BrokersManagementdata.ImagePath"
            class="handle-image"
            alt="User Image"
            style="width: 400px; height: 300px;"
          />
        </div>

        <vx-card class="mb-4" color="primary" elevation="2">
          <h3 class="card-title">{{ $t("UserInformation") }}</h3>
          <div class="card-content">
            <!-- Display User Information -->
            <div class="info-item">
              <i class="feather icon icon-user icon-blue"></i>
              <span class="info-text">{{ BrokersManagementdata.NameAR }}</span>
            </div>
            <div class="info-item">
              <i class="feather icon icon-map-pin icon-blue"></i>
              <span class="info-text">{{ BrokersManagementdata.AddressAR }}</span>
            </div>
            <div class="info-item">
              <i class="feather icon icon-phone icon-blue"></i>
              <span class="info-text">{{ BrokersManagementdata.Phone }}</span>
            </div>
            <div class="info-item">
              <i class="feather icon icon-mail icon-blue"></i>
              <span class="info-text">{{ BrokersManagementdata.Email }}</span>
            </div>
          </div>
        </vx-card>
      </div>
      <div class="w-2/3">
        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
          <h3 style="padding: 15px;">{{ $t("RateAndNotes") }}</h3>
          </div>

       <div id="data-list-list-view" class="data-list-container mb-4">
        <vs-table v-if="BrokersManagementdata.BrokerRates && BrokersManagementdata.BrokerRates.length > 0" ref="table" id="Scroll" pagination :max-items="itemsPerPage" :data=" BrokersManagementdata.BrokerRates"  style="padding-top: 5px;" elevation="2" >

          <template slot="thead">
            <vs-th style="font-size: 16px; font-family: almarai; color: green;" sort-key="UnitModel">{{ $t("Review") }}</vs-th>
            <vs-th style="font-size: 16px; font-family: almarai; color: green;" sort-key="Name">{{ $t("Date") }}</vs-th>
            <vs-th style="font-size: 16px; font-family: almarai; color: green;" sort-key="Status">{{ $t("Stars") }}</vs-th>
          </template>
          <template slot-scope="{data}">
            <tbody>
              <tr v-for="(tr, indextr) in data" :key="indextr" >
                <vs-td><p class="product-name font-medium truncate">{{ tr.Review }}</p></vs-td>
                <vs-td><p class="product-name font-medium truncate">{{ tr.Date }}</p></vs-td>
                <vs-td>
                    <span class="product-name font-medium truncate Stars">
                      <template v-for="index in numperOfStars(tr.Stars)" :td="index">

                             &#9733;

                      </template>
                    </span>
                </vs-td>
              </tr>
            </tbody>
          </template>
        </vs-table>
        <div v-else class="no-data-message">
            <p>لا يوجد تقيمات او تعليقات بعد </p>
          </div>
      </div>
    </div>
  </div>

      <!-- Cards -->

      <!--test-->
     <div class="card-container flex flex-wrap justify-center items-center">
  <h3 style="padding: 15px;">{{ $t("RealEstateAds") }}</h3>
</div>
<vs-table :data="BrokersManagementdata.CustomerProperties" pagination :max-items="itemsPerPage">
    <template v-slot:default="{ data }">
      <div class="card-container" v-if="data && data.length > 0">
        <div class="card-container flex flex-wrap justify-center items-center">
          <div
            v-for="(prop, index) in data"
            :key="index"
            @click="openDetails(prop.Id)"
            class="card bg-white shadow-lg rounded-lg p-0 m-4"
            style="width:900px; height: 350px;"
          >
            <div class="scrolling-content flex flex-col h-full">
              <div class="img-container flex items-center justify-center" style="height: 50%;">
                <img
                  v-if="!prop.ImagePath"
                  :src="baseURL + 'Uploads/empty-image.png'"
                  class="img-thumbnail"
                  style="width: 100%; height: 100%; object-fit: cover;"
                />
                <img
                  v-else
                  :src="baseURL + '/' + prop.ImagePath"
                  class="img-thumbnail"
                  style="width: 100%; height: 100%; object-fit: cover;"
                />
              </div>
              <div class="card-header flex items-center justify-center">
                <i class="feather icon-home mr-2" style="color: #2196F3;"></i>
                <span
                  class="card-heading text-xl font-bold mb-2"
                  style="font-size: 10px; font-weight: bold;"
                >{{ truncatedDescription(prop.NameAR) }}</span>
              </div>
              <div class="card-body text-gray-700 flex-grow" title="الوصف" style="padding-right: 8px;">
                {{ truncatedDescription(prop.DescraptionAr) }}
              </div>
              <div class="card-footer text-gray-500 p-2">
                <div title="الموقع" class="flex items-center">
                  <i class="feather icon-map-pin mr-2" style="color: #2196F3;"></i>
                  <span style="margin-right: 10px;">{{ truncatedDescription(prop.AddressAR) }}</span>
                </div>
                <div title="السعر" class="flex items-center">
                  <i class="feather icon-dollar-sign" style="color: #2196F3;"></i>
                  <span class="info-text">{{ prop.Price }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="no-data-message">
        <p>لم يقم بإضافة اعلانات عقارية بعد</p>
      </div>
    </template>
  </vs-table>

      <!-- Services Subscribtions -->
      <div id="data-list-list-view" class="data-list-container mb-4">
        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
            <h3 style="padding-top: 15px;">{{ $t("SubscriptionStatus") }}</h3>
          </div>
        <vs-table v-if="BrokersManagementdata.BrokerSubscriptions && BrokersManagementdata.BrokerSubscriptions.length > 0" ref="table" id="Scroll" pagination :max-items="itemsPerPage" :data="BrokersManagementdata.BrokerSubscriptions" style="padding-top: 5px;" elevation="2" >

          <template slot="thead">
            <vs-th style="font-size: 16px; font-family: almarai; color: green;" sort-key="UnitModel">{{ $t("StartDate") }}</vs-th>
            <vs-th style="font-size: 16px; font-family: almarai; color: green;" sort-key="Name">{{ $t("EndDate") }}</vs-th>
            <vs-th style="font-size: 16px; font-family: almarai; color: green;" sort-key="Status">{{ $t("Status") }}</vs-th>
          </template>
          <template slot-scope="{data}">
            <tbody>
              <tr v-for="(tr, indextr) in data" :key="indextr" >
                <vs-td><p class="product-name font-medium truncate">{{ tr.StartDate }}</p></vs-td>
                <vs-td><p class="product-name font-medium truncate">{{ tr.EndDate }}</p></vs-td>
                <vs-td><p class="product-name font-medium truncate">{{ tr.Status }}</p></vs-td>
              </tr>
            </tbody>
          </template>
        </vs-table>
        <div v-else class="no-data-message">
            <p>غير مشترك فى اى باقات بعد</p>
          </div>
    </div>
      </div>

  </template>

  <script>
  import VxCard from "../../components/vx-card/VxCard.vue";
  import { domain } from "@/gloabelConstant.js";
  import moduleUsersManagement from "@/store/UsersManagement/Broker/moduleUsersManagement.js";

  export default {
    components: {
      VxCard
    },
    data() {
      return {
        baseURL: domain,
        user_not_found: false,
        activeTab: 0,
        itemsPerPage:2

      };
    },
    computed:{

      BrokersManagementdata() {
      debugger;
        var OldData=this.$store.state.UsersManagement.UsersManagement;

      return OldData

    },

    },
    methods: {
      openDetails(id) {
        debugger;
      this.$router.push(`/CustomerAds/AdsDetails/${id}`);
    },

      numperOfStars(numStars) {
    const stars = [];
    for (let i = 0; i < numStars; i++) {
      stars.push(i);
    }
    return stars;
  },
      getIcon(key) {
        switch (key) {
          case "ownerName":
            return "icon-user";
          case "address":
            return "icon-map-pin";
          case "phoneNumber":
            return "icon-phone";
          case "Email":
            return "icon-mail";
          case "status":
            return "icon-check-circle";
          case "nationalCardNumber":
            return "icon-credit-card";
          default:
            return "";
        }
      }
    ,
      formatDate(dateString) {
        const date = new Date(dateString);
        return date.toLocaleDateString(); // Adjust format as needed
      },
      openConfirm(data) {
        this.rowDataForDelete = data;
        const confirmText = data.isPaid ? this.$t("هل انت متأكد انك تريد دفع هذا المستحق") : this.$t("هل انت متأكد انك تريد سحب هذا المستحق");
        this.$vs.dialog({
          type: "confirm",
          color: "success",
          title: this.$t("PaymentConfirmation"),
          text: confirmText,
          accept: this.acceptAlert,
          acceptText: this.$t("Confirm"),
          cancelText: this.$t("Cancel"),
        });
      },

      truncatedDescription(description) {
        const words = description.split(' ');
        if (words.length > 5) {
          return words.slice(0, 5).join(' ') + '...';
        }
        return description;
      }
    },
    created() {
      // if (!moduleUsersManagement.isRegistered) {
      this.$store.registerModule("UsersManagement", moduleUsersManagement);
      moduleUsersManagement.isRegistered = true;
   // }
    debugger;
    var x=this.$route.params.Id;
    this.$store.dispatch("UsersManagement/GetBrokerItem", x);
    },
  };
  </script>

  <style scoped>
  .w-1\3 {
    width: 33.33%;
    margin-right: 20px; /* Adjust spacing */
  }

  .w-2\3 {
    width: 66.66%;
  }
  .toggle-switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 24px;
  }

  .toggle-switch input {
    display: none;
  }
  .image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px; /* Adjust height as needed */
}
.card-title {
    padding-bottom: 20px;
  }

  .card-content {
    margin-bottom: 10px;
  }

  .info-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .info-item .feather {
    margin-right: 10px;
    color: #2196F3;
  }

  .data-list-container {
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 8px;
    margin-bottom: 20px; /* Adjust spacing */
  }

  .section-title {
    text-align: center;
    padding-bottom: 10px;
    padding-top: 20px;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 24px;
  }
  .card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Adds spacing between cards */
}

.card {
  flex: 1 1 calc(33.33% - 20px); /* Adjusts the card size to fit 3 per row, accounting for gap */
  max-width: calc(33.33% - 20px); /* Ensures cards don't exceed 33.33% width minus the gap */
  box-sizing: border-box; /* Ensures padding and border are included in the width */
}

.info-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info-text {
  flex-grow: 1;
}
  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: #2196F3;
  }
  .no-data-message {
  text-align: center;
  padding: 20px;
  color: green;
  font-weight: bold;
  }
.Stars{
  width: 100%;
  text-align: center;
  font-size: 40px;
  color:yellow;
}
  input:checked + .slider:before {
    -webkit-transform: translateX(24px);
    -ms-transform: translateX(24px);
    transform: translateX(24px);
  }
  .custom-textarea {
    border: 1px solid red;
    border-radius: 5px;
    padding: 10px;
    height: 100px;
    resize: none;
  }

  .custom-textarea:focus {
    outline: none;
    border-color: red;
  }
  .selected-row {
    background-color: #f0f0f0;
    cursor: pointer;
  }

  .selected-row:hover {
    background-color: #e0e0e0;
  }
  .img-thumbnail {
    object-fit: cover; /* Maintain aspect ratio */
    border-radius: 8px; /* Add rounded corners */
  }
  </style>
